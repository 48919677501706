<script>
import axios from 'axios'
import { onMounted, reactive, ref } from 'vue'

export default {
  name: 'AnnounceComponent',
  setup () {
    const announceVisible = ref(false)
    const announce = reactive({
      link: null,
      description: 'asddsadsasdasda asdasd adsdasasd asdasddsa'
    })
    const getAnnounce = async () => {
      try {
        const responce = await axios('/v1/announce')
        announceVisible.value = true

        if (responce.data.status === 'ok') {
          announce.link = responce.data.data[0].link
          announce.description = responce.data.data[0].description
        } else {
          announce.link = null
          announce.description = null
          announceVisible.value = false
        }
      } catch (e) {
        announceVisible.value = false
        announce.link = null
        announce.description = null
      }
    }
    onMounted(async () => {
      await getAnnounce()
    })

    return {
      announce,
      announceVisible
    }
  }
}
</script>

<template>
    <div class="announce" v-if="announceVisible">
    <div class="announce__container container">
      <div class="announce__wrapper">
        <div class="announce__top">
          <img src="@/assets/uploads/icon/common/alert.svg" alt="восклицательный знак" />
          <a v-if="announce.link" :href="announce.link" target="_blank" class="announce__link">
            <span class="text">
              {{ announce.description }}
            </span>
          </a>

          <p v-else class="announce__text">
            {{ announce.description }}
          </p>
        </div>

        <button @click="announceVisible = false" class="announce__close" type="button">
          <img src="@/assets/uploads/icon/common/close-alert.svg" alt="знак закрытия" />
        </button>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>

.announce {
  margin-top: 10px;
  margin-bottom: 10px;
}

.announce__container {}

.announce__link {
  text-decoration: underline;
}

.announce__wrapper {
  border-radius: 8px;
  background: $orange;
  gap: 10px;
  padding: 8px;
  display: flex;
  justify-content: space-between;
}

.announce__link {}

.announce__top {
  display: flex;
  align-items: start;
  flex: 1;
  gap: 10px;
}

.announce__text {
  color: #062e4f;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
}

.announce__close {
  padding: 5px;
  align-self: start;
  border: unset;
}

</style>
