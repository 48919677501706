<script>
import {
  mobileMenuButtons,
  mobileMenuButtonsDoctor
} from '../../../constants/mobileMenuButtons'
import MenuSmallTile from './MenuSmallTile.vue'
import MenuWideTile from './MenuWideTile.vue'
export default {
  name: 'MenuTilesList',
  components: { MenuSmallTile, MenuWideTile },
  setup () {
    return {
      mobileMenuButtonsDoctor,
      mobileMenuButtons

    }
  }
}
</script>

<template>
  <div class="tiles-wrapper">
    <template v-for="tile in mobileMenuButtons" :key="tile.smallIconName">
      <MenuWideTile
        v-if="tile.isWide"
        :to="tile.to"
        :smallIconName="tile.smallIconName"
        :bgColor="tile.bgColor"
        :title="tile.title"
        :subtitle="tile.subtitle"
        :bigIconName="tile.bigIconName" />

      <MenuSmallTile
        v-if="!tile.isWide"
        :to="tile.to"
        :smallIconName="tile.smallIconName"
        :bgColor="tile.bgColor"
        :title="tile.title"
        :subtitle="tile.subtitle" />
    </template>
  </div>
</template>

<style lang="scss" scoped>
.tiles-wrapper {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 8px;
  margin-bottom: 24px;
}
</style>
